<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Users</h3>
          <div class="justify-content-between">
            <router-link :to="{name: 'admin_create_user'}" class="ps-btn btn-sm"><i class="icon-plus"></i> Create User</router-link>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.name }}</td>
              <td>
                <span v-if="user.email_verified_at" class="text-success">{{ user.email }} </span>
                <span v-if="!user.email_verified_at" class="text-danger">{{ user.email }} </span>

              </td>
              <td>
                <span v-if="user.phone_number_verified_at" class="text-success">{{ user.phone_number }} </span>
                <span v-if="!user.phone_number_verified_at" class="text-danger">{{ user.phone_number }} </span>
              </td>
              <td>
                <span v-if="user.is_admin" class="text-info">Administrator </span>
                <span v-else-if="user.shop_id" class="text-success">Shop Owner</span>
                <span v-else class="text-primary">Customer</span>
              </td>
              <td>
                <router-link :to="{name: 'admin_edit_user', params: {id: user.id}}">
                  <i class="icon-pencil"></i>
                </router-link>
                <router-link :to="{name: 'admin_change_user_password', params: {id: user.id}}" class="ml-3">
                  <i class="icon-lock"></i>
                </router-link>
                <a class="ml-3 text-danger" href="#" @click="deleteUser(user.id)"><i class="icon-trash" /></a>
              </td>
            </tr>
            </tbody>
            <tfoot>
              <tr v-if="(!meta || meta.total === 0) && !isLoading">
                <td colspan="7" class="text-center m-4 text-danger p-4">No users available</td>
              </tr>
              <tr v-else-if="isLoading">
                <td colspan="7" class="text-center m-4 text-danger">Loading users...</td>
              </tr>
              <tr v-else>
                <td class="text-right" colspan="7">
                  <ul class="pagination text-right">
                    <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                      <a href="#">Prev Page<i class="icon-chevron-left"></i></a>
                    </li>
                    <li class="active"><a href="#">Page {{ pageNumber }}</a></li>
                    <!--                      <li><a href="#">3</a></li>-->
                    <li v-on:click="goToNextPage()" v-if="hasNextPage">
                      <a href="#">Next Page<i class="icon-chevron-right"></i></a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "List",
  data() {
    return {
      users: [],
      meta: null,
      isLoading: false,
      isDeleting: false,
      links: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.isLoading = true;
      let url = 'users';
      if (this.url) {
        url = this.url;
      }

      axios.get(url).then((response) => {
        this.users = response.data.data;
        this.meta = response.data.meta;
        this.links = response.data.links;
        this.count = response.data.meta.total;
        this.pageNumber = response.data.meta.current_page;
        this.handleNextPage(response.data.links);
        this.handlePrevPage(response.data.links);
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
      });
    },

    deleteUser(id) {
      if (confirm('Are you sure you want to delete this user')) {
        this.isDeleting = true;
        axios.delete('users/' + id).then((response) => {
          this.getUsers()
          this.isDeleting = false;
        }).catch((error) => {
          this.isDeleting = false;
        });
      }
    },

    scrollTop() {
      let element = document.getElementsByClassName("table-responsive");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
    },

    goToNextPage: function()
    {
      this.url = this.nextPage;
      if (this.url) {
        this.getUsers()
      }
      this.scrollTop();
    },

    goToPrevPage: function()
    {
      this.url = this.prevPage;
      if (this.url) {
        this.getUsers()
      }
      this.scrollTop();
    },

    handleNextPage(links)
    {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false
      }
    },

    handlePrevPage(links)
    {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false
      }
    }

  }
}
</script>

<style scoped>

</style>